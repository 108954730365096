import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { b64DecodeUnicode } from '../../../utils/util';
import ZoomContext from '../../../context/zoom-context';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { message } from 'antd';
const apiUrl = process.env.REACT_APP_SERVER_URI;
const CountdownTimer: React.FC = (props) => {
  const zmClient = useContext(ZoomContext);

  useParticipantsChange(zmClient, () => {
    const allUsers=zmClient.getAllUser();
    if (allUsers) {
      allUsers.forEach(user => {
        if (user.isHost) {
          setIsHostStarted(user.isHost);
          return;
        }
      });
    }
  });
  //const {hostinfo}=props;
  const [time, setTime] = useState<number>(5000);
  const [isHostStarted, setIsHostStarted] = useState(false);

   const onTimeout = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const bId = queryParams.get('mbid');
    const cId = queryParams.get('cId');
    if (bId && cId) {
      window.location.href = `${process.env.REACT_APP_SERVER_URI}/video-call-thank-you?mId=${cId}&b=${bId}`;
    }else{
       window.location.href = `${process.env.REACT_APP_SERVER_URI}/date-history?bId=${bId}`;
    }
  };
  useEffect(() => {
   
    const timeoutId = setTimeout(() => {
      if(isHostStarted==false){
        return false;
      }

    const queryParams2 = new URLSearchParams(window.location.search);
    const mbid = queryParams2.get('mbid');

     const apiHeaders = new Headers({
        "Content-Type": "application/json",
        // Add any additional headers if needed
      });

      const requestBody = {
        bId: mbid
        // Add other properties if needed
      };

      const requestOptions = {
        method: 'POST',
        headers: apiHeaders,
        body: JSON.stringify(requestBody),
      };

    

    fetch(`${apiUrl}/wp-json/video-call/v1/timer/`, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        }).then(result => {
            if(result.status && result?.time>0){
              localStorage.setItem(`localTime_${b64DecodeUnicode(mbid)}`,result?.time.toString());
              return setTime(result?.time);
            }else{
              message.error('The time limit has expired.');
              onTimeout();
            }
            
        });
        
    }, 1000);

  }, [time,isHostStarted]);

  const formatTime = () => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
  };


  if(time==5000){
    return null;
  }

  return (
    <div className="countdown-timer">
      <div id="countdownTimer">{formatTime()}</div>
    </div>
  );
};





export default CountdownTimer;
