import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Video from './feature/video/video';
import VideoSingle from './feature/video/video-single';
import VideoAttach from './feature/video/video-attach';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LoadingLayer from './component/loading-layer';
import Chat from './feature/chat/chat';
import Command from './feature/command/command';
import Subsession from './feature/subsession/subsession';
import { MediaStream } from './index-types';
import { devConfig } from './config/dev';
import { b64DecodeUnicode, generateVideoToken } from './utils/util';
import './App.css';

 
interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;

    useVideoPlayer?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

function App() {
const apiUrl = process.env.REACT_APP_SERVER_URI;
let meetingArgs: any = Object.fromEntries(new URLSearchParams(location.search));
const enforceGalleryView=1;
const enforceVB=0;
const useVideoPlayer=1;
const [name, setName] = useState<string>('');
const [topic, setTopic] = useState<string>('');
const [signature, setSignature] = useState<string>('');
const [bookdatetime, setBookdatetime] = useState<string>('');
const [isHostStarted, setIsHostStarted] = useState<boolean>(false);
const [isRoleHost, setIsRoleHost] = useState<number>(0);

useEffect(()=>{
 
  if (!meetingArgs.sdkKey || !meetingArgs.topic || !meetingArgs.name || !meetingArgs.signature) {
    meetingArgs = { ...devConfig, ...meetingArgs };
  }
  meetingArgs.password='';
  const {mbid,webn}=meetingArgs;
  const apiHeaders = new Headers({
    "Content-Type": "application/json",
    // Add any additional headers if needed
  });
  const userRole=b64DecodeUnicode(webn);
  setIsRoleHost(Number(userRole));
  const requestBody = {
    bId: mbid
    // Add other properties if needed
  };

  const requestOptions = {
    method: 'POST',
    headers: apiHeaders,
    body: JSON.stringify(requestBody),
  };

  fetch(`${apiUrl}/wp-json/video-call/v1/get-info/`, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        }).then(result => {
           if(result.status){
            const {booking}=result;
            const {book_by_name,book_by_uid,book_datetime,book_to_uid,book_to_name,duration,id,is_meeting_end}=booking;
            setBookdatetime(book_datetime);
              try {
                meetingArgs.name =(userRole=='1')?book_to_name:book_by_name;
                setName(meetingArgs.name);
              } catch (e) {}

              try {
                meetingArgs.role = Number(userRole);
              } catch (e) {}

              try {
                const createTopic=`${booking?.book_to_name} | ${booking?.book_by_name}  #${booking?.id}.`;
                meetingArgs.topic =createTopic;
                setTopic(createTopic);
              } catch (e) {}

              
               if (signature=='') {
                 const getTokenValue=generateVideoToken(
                    meetingArgs.sdkKey,
                    meetingArgs.sdkSecret,
                    meetingArgs.topic,
                    meetingArgs.sessionKey,
                    meetingArgs.userIdentity,
                    Number(userRole),
                    meetingArgs.cloud_recording_option,
                    meetingArgs.cloud_recording_election,
                    meetingArgs.telemetry_tracking_id
                  );
                  meetingArgs.signature=getTokenValue
                  setSignature(getTokenValue);
                  //console.log("meetingArgs.....",meetingArgs);
              }
            
           }
            
        });

},[]);
  



  const {
      sdkKey,
      password,
      webEndpoint: webEndpointArg,
      customerJoinId,
      lang,
  } = meetingArgs;

  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [isVideoStart, setIsVideoStart] = useState(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  const zmClient = useContext(ZoomContext);
  let webEndpoint: any;
  webEndpoint = window?.webEndpoint ?? 'zoom.us';
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);
  useEffect(() => {
    const init = async () => {
      await zmClient.init('en-US', `${window.location.origin}/lib`, {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false
      });
      try {
        // setLoadingText('Joining the session...');
        // await zmClient.join(topic, signature, name, password).catch((e) => {
        //   console.log(e);
        // });
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        message.error(e.reason);
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    sdkKey,
    signature,
    zmClient,
    topic,
    name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
    lang,
    vbWithoutSAB
  ]);
  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        //console.log('getSessionInfo', zmClient.getSessionInfo());
        //console.log('getAllUser..', zmClient.getAllUser());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host'
          });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);


const updateVideoState = useCallback(async (newState: any) => {
    localStorage.setItem('ismycameraOn', newState);
    setIsVideoStart(newState);
  },[]);
 
  const onLeaveOrJoinSession = useCallback(async (history: any) => {
    const queryParams2 = new URLSearchParams(window.location.search);
    const mbid = queryParams2.get('mbid');
    const apiHeaders = new Headers({
      "Content-Type": "application/json",
      // Add any additional headers if needed
    });
     const requestBody = {
      bId: mbid
      // Add other properties if needed
    };

    if (status === 'closed') {
      
      try{
        if(isRoleHost==1){
          setIsLoading(true);
          setLoadingText('Joining the session...');
           await zmClient.join(topic, signature, name, password);
          setIsHostStarted(true);
           history.push(`/video${location.search}`);
          
          const requestOptions = {
            method: 'POST',
            headers: apiHeaders,
            body: JSON.stringify(requestBody),
          };
        
          fetch(`${apiUrl}/wp-json/video-call/v1/model-joinmeeting/`, requestOptions)
                .then(response => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  return response.json();
                });
           setIsLoading(false);
           setLoadingText('');
        }else{

             setLoadingText('Joining the session...');
                       zmClient.join(topic, signature, name, password).then(()=>{
                        history.push(`/video${location.search}`);
                        setIsLoading(false);
                        setLoadingText('');
                       });
            const requestOptions = {
              method: 'POST',
              headers: apiHeaders,
              body: JSON.stringify(requestBody),
            };
          
            fetch(`${apiUrl}/wp-json/video-call/v1/isuser-join/`, requestOptions)
              .then(response => {
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
              });
          // const requestOptions = {
          //   method: 'POST',
          //   headers: apiHeaders,
          //   body: JSON.stringify(requestBody),
          // };
          // setIsLoading(true);
          // setLoadingText('Establishing connection...');
          // fetch(`${apiUrl}/wp-json/video-call/v1/ismodel-join/`, requestOptions)
          //       .then(response => {
          //         if (!response.ok) {
          //           throw new Error(`HTTP error! Status: ${response.status}`);
          //         }
          //         return response.json();
          //       }).then((result)=>{
          //           if(result.status){
          //             setLoadingText('Joining the session...');
          //              zmClient.join(topic, signature, name, password).then(()=>{
          //               history.push(`/video${location.search}`);
          //               setIsLoading(false);
          //               setLoadingText('');
          //              });
                       
          //           }else{
          //                setIsLoading(false);
          //               setLoadingText('');
          //                 message.warning(
          //                   'The meeting cannot begin until the host initiates it. Please wait for the host to start the meeting.'
          //                 );
          //           }
          //       });

          //  await zmClient.join(topic, signature, name, password);
          //  const participants = await zmClient.getAllUser();
          //  participants.forEach(async (user) => {
          //     if(user.isHost){
          //         setIsHostStarted(true);
          //     }
          //  });

          //  if(!isHostStarted){
          //    await zmClient.leave();
          //   setIsLoading(false);
          //   setLoadingText('');
          //     message.warning(
          //       'The meeting cannot begin until the host initiates it. Please wait for the host to start the meeting.'
          //     );
          //  }else{
          //   history.push(`/video${location.search}`);
          //   setIsLoading(false);
          //   setLoadingText('');
          //  }
          // console.log("participants",participants);
          // if(isHostStarted){
          //   await zmClient.join(topic, signature, name, password);
          //   setIsHostStarted(true);
          //    history.push(`/video${location.search}`);
          //    setIsLoading(false);
          //    setLoadingText('');
          // }else{
          //   setIsLoading(false);
          //   setLoadingText('');
          //     message.warning(
          //       'The meeting cannot begin until the host initiates it. Please wait for the host to start the meeting.'
          //     );
          // }
        }
        const zoomSessionId = zmClient.getSessionInfo().sessionId;
        const updateSessionRequestBody = {
          bId: mbid,
          sID: zoomSessionId
        };
        const updateSessionRequestOptions = {
          method: 'POST',
          headers: apiHeaders,
          body: JSON.stringify(updateSessionRequestBody),
        };
      
        fetch(`${apiUrl}/wp-json/video-call/v1/booking-updateSession/`, updateSessionRequestOptions)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          });
      }catch(e){
        //console.log(e);
        setIsLoading(false);
      }
    } else if (status === 'connected') {
      await zmClient.leave();
      message.warn('You have left the session.');
    }
  }, [zmClient, status, topic, signature, name, password,history]);
  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  
  return (
    <div className="App">
      {(loading || !name) && <LoadingLayer content={loadingText} />}
      {!loading && name && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Router>
            <Switch>
              <Route
                path="/"
                render={(meetingArgs) => <Home {...meetingArgs} userRole={isRoleHost} name={name} zmClient={zmClient} bookdatetime={bookdatetime} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} updateVideoState={updateVideoState} />}
                exact
              />
              <Route path="/index.html" component={Home} exact />
              <Route path="/chat" component={Chat} />
              <Route path="/command" component={Command} />
              <Route
                path="/video"
                component={VideoAttach}
              />
               {/* <Route
                path="/video"
                component={isSupportGalleryView ? (galleryViewWithAttach ? VideoAttach : Video) : VideoSingle}
              /> */}
              <Route path="/subsession" component={Subsession} />
              <Route path="/preview" component={Preview} />
            </Switch>
          </Router>
        </ZoomMediaContext.Provider>
      )}
    </div>
  );
}

export default App;
