import React,{useCallback,useState,useEffect} from 'react';
import { IconFont } from '../../../component/icon-font';
import { Radio,Tooltip, Button,Modal,Divider,Spin,message } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import { b64DecodeUnicode } from '../../../utils/util';

const apiUrl = process.env.REACT_APP_SERVER_URI;
interface ExtendedMeetingProps {
  className?: string;
  zmClient: any;
  
}
interface Data {
  totalfee?: number;
  price?: number;
  servicefee?: number;
  communicationTax?: number;
  // Add other properties if needed
}
const ExtendedMeeting=(props:ExtendedMeetingProps)=>{
	 const {
	 	className,
		 zmClient
  } = props;
  
  const queryParams = new URLSearchParams(window.location.search);

  const [open, setOpen] = useState(false);
  const [isNewRequest, setIsNewRequest] = useState(false);
  const [isUserSendRequest, setIsUserSendRequest] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState('5');
  const [isAvailable, setIsAvailable] = useState(true);
  const localmbid = queryParams.get('mbid');
  let stringValueOrNull: string | null = localStorage.getItem(`speaky_timer_${b64DecodeUnicode(localmbid)}`);
  let localTime: number = !isNaN(parseInt(stringValueOrNull || '', 10)) ? parseInt(stringValueOrNull || '', 10) : 0;
  const [seconds, setSeconds] = useState(0);
  const [extendedId, setExtendedId] = useState(null);
  const [duration, setDuration] = useState(0);
  const [amount, setAmount] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [isUserTimeUp, setIsUserTimeUp] = useState(0);
  const [isboxhit, setIsboxhit] = useState(false);
  const [puptitle, setPuptitle] = useState('Extend Video Chat');
  
  
  
const handleDurationChange = (e: RadioChangeEvent): void => {
  // Update the selectedDuration state when the user changes the duration
  setSelectedDuration(e.target.value);
};

  const fetchData =() => {
  	setLoading(true);
  	try {
  
  const mbid = queryParams.get('mbid');

	  if (!apiUrl) {
	    throw new Error("REACT_APP_SERVER_URI is not defined");
	  }

	  if (!mbid) {
	    throw new Error("Missing 'mbid' parameter");
	  }
	  if (!selectedDuration) {
	    throw new Error("Missing 'selectedDuration' parameter");
	  }

	  const apiHeaders = new Headers({
	    "Content-Type": "application/json",
	    // Add any additional headers if needed
	  });

	  const requestBody = {
	    bId: mbid,
	    dur: selectedDuration
	    // Add other properties if needed
	  };

	  const requestOptions = {
	    method: 'POST',
	    headers: apiHeaders,
	    body: JSON.stringify(requestBody),
	  };

	  fetch(`${apiUrl}/wp-json/video-call/v1/price-info/`, requestOptions)
	    .then(response => {
	      if (!response.ok) {
	        throw new Error(`HTTP error! Status: ${response.status}`);
	      }
	      return response.json();
	    })
		    .then(result => {
		    		if(result.status && result.data){
		    			setData(result.data);
		    		}
		      // Do something with the result if needed
		    })
		    .catch(error => {
		     setLoading(false);
		    })
		    .finally(() => {
		      setLoading(false);
		    });
		} catch (error) {
		  setLoading(false);
		}
				
    };

    const minutesToSeconds = (minutes: number): number => {
      return minutes * 60;
    };

    const submitHandel=()=>{

    	setLoading(true);
  	try {
 
  const mbid = queryParams.get('mbid');

	  if (!apiUrl) {
	    throw new Error("REACT_APP_SERVER_URI is not defined");
	  }

	  if (!mbid) {
	    throw new Error("Missing 'mbid' parameter");
	  }
	  if (!selectedDuration) {
	    throw new Error("Missing 'selectedDuration' parameter");
	  }

	  const apiHeaders = new Headers({
	    "Content-Type": "application/json",
	    // Add any additional headers if needed
	  });

	  const requestBody = {
	    bId: mbid,
	    dur: selectedDuration,
	    communicationTax: data ? data.communicationTax : 0
	    // Add other properties if needed
	  };

	  const requestOptions = {
	    method: 'POST',
	    headers: apiHeaders,
	    body: JSON.stringify(requestBody),
	  };

	  fetch(`${apiUrl}/wp-json/video-call/v1/new-booking/`, requestOptions)
	    .then(response => {
	      if (!response.ok) {
	        throw new Error(`HTTP error! Status: ${response.status}`);
	      }
	      return response.json();
	    })
		    .then(result => {
		    		if(result.status){
		    			setOpen(false);
						setIsboxhit(true);
		    		const storedValue =localStorage.getItem(`speaky_timer_${b64DecodeUnicode(mbid)}`);
		    			if(storedValue && storedValue !== null){
		    				 localTime=parseInt(storedValue, 10);

		    					if(!isNaN(localTime)){
		    						localTime+=minutesToSeconds(parseInt(selectedDuration));
		    							localStorage.setItem(`speaky_timer_${b64DecodeUnicode(mbid)}`,localTime.toString());
		    					}
		    				
		    			}
		    			
		    			 message.success(result.msg);

		    		}
		      // Do something with the result if needed
		    })
		    .catch(error => {
		      setLoading(false);
		    })
		    .finally(() => {
		      setLoading(false);
		    });
		} catch (error) {
		  setLoading(false);
		}

    }


    const checkAvilableTime=()=>{
    	try {
		  
		  const mbid = queryParams.get('mbid');

			  if (!apiUrl) {
			    throw new Error("REACT_APP_SERVER_URI is not defined");
			  }

			  if (!mbid) {
			    throw new Error("Missing 'mbid' parameter");
			  }
			  const apiHeaders = new Headers({
			    "Content-Type": "application/json",
			    // Add any additional headers if needed
			  });

			  const requestBody = {
			    bId: mbid,
			    dur: selectedDuration
			  };

				  const requestOptions = {
				    method: 'POST',
				    headers: apiHeaders,
				    body: JSON.stringify(requestBody),
				  };

				  fetch(`${apiUrl}/wp-json/video-call/v1/check-avilable/`, requestOptions).then(response => {
				      if (!response.ok) {
				        throw new Error(`HTTP error! Status: ${response.status}`);
				      }
				      return response.json();
				    }).then((response)=>{
				  		if(response.status){
				  			setIsAvailable(false);
				  		}else{
				  			setIsAvailable(true);
				  		}
				  });
			} catch (error) {
			  setIsAvailable(false);
			}
    }

	 useEffect(() => {
	 	checkAvilableTime();
    fetchData();
  }, [selectedDuration]);

  useEffect(()=>{
	if(zmClient.isHost()){
		const queryParams2 = new URLSearchParams(window.location.search);
		const mbid = queryParams2.get('mbid');
		 const apiHeaders = new Headers({
			"Content-Type": "application/json",
			// Add any additional headers if needed
		  });
	
		  const requestBody = {
			bId: mbid
			// Add other properties if needed
		  };
	
		  const requestOptions = {
			method: 'POST',
			headers: apiHeaders,
			body: JSON.stringify(requestBody),
		  };
		fetch(`${apiUrl}/wp-json/video-call/v1/isExtended-request/`, requestOptions)
			.then(response => {
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }
			  return response.json();
			}).then(result => {
				if(result.status){
					setDuration(result.duration);
					setExtendedId(result.extdenedId);
					setAmount(result?.amt);
					setFirstName(result?.firstName);
				}
				setSeconds(prevSeconds => prevSeconds + 1);
				
			});
	}

	
			
  },[seconds]);

//   useEffect(()=>{
// 	setTimeout(() => {
// 		if(!zmClient.isHost()){

// 			const queryParams2 = new URLSearchParams(window.location.search);
// 			const mbid = queryParams2.get('mbid');

// 			const apiHeaders = new Headers({
// 				"Content-Type": "application/json",
// 				// Add any additional headers if needed
// 			});

// 			const requestBody = {
// 				bId: mbid
// 				// Add other properties if needed
// 			};

// 			const requestOptions = {
// 				method: 'POST',
// 				headers: apiHeaders,
// 				body: JSON.stringify(requestBody),
// 			};

			

// 			fetch(`${apiUrl}/wp-json/video-call/v1/timer/`, requestOptions)
// 				.then(response => {
// 				if (!response.ok) {
// 					throw new Error(`HTTP error! Status: ${response.status}`);
// 				}
// 				return response.json();
// 				}).then(result => {
// 					if(result.status && result?.time>0){
// 						console.log("result?.time",result?.time);
// 						return setIsuserTimeUp(result?.time);
// 					}
// 				});
// 			// setIsuserTimeUp(prevSeconds => prevSeconds + 1);
// 			// console.log("seconds",seconds);
// 			// if(isuserTimeUp>10 && isuserTimeUp<62){
// 			// 	setOpen(true);
// 			// }
// 		}
// 	}, 1000);
//   },[isuserTimeUp]);



	
	setTimeout(() => {
		
		const mbid = queryParams.get('mbid');
		const storedValue =localStorage.getItem(`localTime_${b64DecodeUnicode(mbid)}`);
		if(storedValue && parseInt(storedValue, 10)>60){
			setIsboxhit(false);
		}
		if(!zmClient.isHost()){
			
			if (storedValue) {
				setIsUserTimeUp(parseInt(storedValue, 10)); // Ensure the parsed value is a number
			  }
			
			if(isUserTimeUp>10 && isUserTimeUp<62 && !isboxhit){
				setPuptitle('Your call is almost up! Would you like to extend the call?');
					setOpen(true);
					setIsboxhit(true);
			}
			//console.log("storedValue",storedValue);
		}
	},1000);


  useEffect(()=>{
	if(extendedId){
		setIsNewRequest(true);
	}
  },[extendedId]);


const accecptBooking= ()=>{
	setLoading(true);
	const requestOptions = {
		method: "GET",
	  };
	  
	  fetch(`${apiUrl}?replyMessage=true&Body=accept ${extendedId}`, requestOptions)
		.then((response) => response.text())
		.then((result) =>{
			setIsNewRequest(false);
			setExtendedId(null);
			setDuration(0);
			setLoading(false);
			setSeconds(1);
			message.success('You just accepted the booking.');
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		});
}

const declinetBooking=()=>{
	setLoading(true);
	const requestOptions = {
		method: "GET",
	  };
	fetch(`${apiUrl}?replyMessage=true&Body=decline ${extendedId}`, requestOptions)
		.then((response) => response.text())
		.then((result) =>{
			setIsNewRequest(false);
			setExtendedId(null);
			setDuration(0);
			setLoading(false);
			setSeconds(1);
			message.success('You just decline the booking.');
		})
		.catch((error) =>{
			setLoading(false);
			console.error(error)
		});
}
//   		console.log("zmClient.isHost()",zmClient.isHost());
  	if(!zmClient.isHost()){
		return (
			<div className={classNames('extended-meeting', className)}>
				 {!isAvailable && <Tooltip title="Extend Video Chat">
				 
				  <Button
					  className="vc-button"
					  //icon={<IconFont type="icon-clock" />}
					  size="large"
					  ghost
					  shape="circle"
					  onClick={() => setOpen(true)}
					  disabled={isAvailable}
					><span className='extent-time-text'>Extend</span></Button>
				</Tooltip>}
				<Modal
				title={puptitle}
				centered
				open={open}
				okText="Book now"
				onOk={submitHandel}
				okButtonProps={{ disabled: isAvailable,loading:loading }}
				onCancel={() =>{setIsboxhit(true);setOpen(false)} }
			  >
			  <Spin spinning={loading}>
				<div id="call-duration">
							<h5>1) Select the call duration:</h5>
									<Radio.Group  
									value={selectedDuration}
									onChange={handleDurationChange} 
									buttonStyle="solid">
								  <Radio.Button value="5">5 mins</Radio.Button>
								  <Radio.Button value="15">15 mins</Radio.Button>
								  <Radio.Button value="30">30 mins</Radio.Button>
								</Radio.Group>
								<Divider />
								{data && (
									  <div className="card total-price">
										<strong>The following amount will be debited from your saved card</strong>
										<p>
										  <strong>You will be charged $<span id="charged_amt">{data.totalfee}</span></strong>
										</p>
										<p>
										  (
										  $<span id="sub_amt">{data.price}</span>+ $<span id="service_amt">{data.servicefee}</span> service fee + $<span id="communicationTax">{data.communicationTax}</span> communications tax)
										</p>
									  </div>
									)}
		
						</div>
						</Spin>
			  </Modal>
	
			</div>
		);
	}else{
		return (
			<div className={classNames('extended-meeting', className)}>
					<Modal
						title="Accept Extension Request"
						centered
						open={isNewRequest}
						okText="Accept"
						onOk={accecptBooking}
						cancelText="Decline"
						okButtonProps={{ disabled: loading,loading:loading }}
						onCancel={declinetBooking}
						closable={false}
						keyboard={false}
					>
						  <Spin spinning={loading}>
							<p>{firstName} just requested to extend your current video call for {duration} minutes for ${amount}. Press ‘Accept' to approve or 'Decline’ to decline.</p>
						</Spin>
					</Modal>
			</div>);
	}
	
}

export default ExtendedMeeting;