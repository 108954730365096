/* eslint-disable no-restricted-globals */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import ZoomVideo, { TestMicrophoneReturn, TestSpeakerReturn } from '@zoom/videosdk';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Button,message, Modal, Checkbox } from 'antd';
import { IconFont } from '../../component/icon-font';
import { useMount, useUnmount } from '../../hooks';
import MicrophoneButton from '../video/components/microphone';
import CameraButton from '../video/components/camera';
import { MediaDevice } from '../video/video-types';
import classNames from 'classnames';

import './home.scss';
import '../preview/preview.scss';
// label: string;
// deviceId: string;
let prevMicFeedbackStyle = '';
let micFeedBackInteval: any = '';

let localAudio = ZoomVideo.createLocalAudioTrack();
let localVideo = ZoomVideo.createLocalVideoTrack();
let allDevices;

const mountDevices: () => Promise<{
  mics: MediaDevice[];
  speakers: MediaDevice[];
  cameras: MediaDevice[];
}> = async () => {
  allDevices = await ZoomVideo.getDevices();
  const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'videoinput';
  });
  const micDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'audioinput';
  });
  const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'audiooutput';
  });
  return {
    mics: micDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    speakers: speakerDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    cameras: cameraDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    })
  };
};

const updateMicFeedbackStyle = () => {
  const newVolumeIntensity = localAudio.getCurrentVolume();
  let newMicFeedbackStyle = '';

  if (newVolumeIntensity === 0) {
    newMicFeedbackStyle = '';
  } else if (newVolumeIntensity <= 0.05) {
    newMicFeedbackStyle = 'mic-feedback__very-low';
  } else if (newVolumeIntensity <= 0.1) {
    newMicFeedbackStyle = 'mic-feedback__low';
  } else if (newVolumeIntensity <= 0.15) {
    newMicFeedbackStyle = 'mic-feedback__medium';
  } else if (newVolumeIntensity <= 0.2) {
    newMicFeedbackStyle = 'mic-feedback__high';
  } else if (newVolumeIntensity <= 0.25) {
    newMicFeedbackStyle = 'mic-feedback__very-high';
  } else {
    newMicFeedbackStyle = 'mic-feedback__max';
  }
  const micIcon: any = document.getElementById('auido-volume-feedback');
  if (prevMicFeedbackStyle !== '' && micIcon) {
    micIcon.classList.toggle(prevMicFeedbackStyle);
  }

  if (newMicFeedbackStyle !== '' && micIcon) {
    micIcon.classList.toggle(newMicFeedbackStyle);
  }
  console.log(newMicFeedbackStyle, newVolumeIntensity);
  prevMicFeedbackStyle = newMicFeedbackStyle;
};

// const { Option } = Select;

const { Meta } = Card;
interface HomeProps extends RouteComponentProps {
  name: string;
  status: string;
  bookdatetime: string;
  userRole: number;
  zmClient: any;
  onLeaveOrJoinSession: (history: any) => void;
  updateVideoState: (callValue: any)=> void;
}
const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { name,history, status, onLeaveOrJoinSession, updateVideoState,bookdatetime,zmClient,userRole } = props;
  const onCardClick = (type: string) => {
      history.push(`/${type}${location.search}`);
  };

  const apiUrl = process.env.REACT_APP_SERVER_URI;
  const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [activeCamera, setActiveCamera] = useState('');
  const [outputLevel, setOutputLevel] = useState(0);
  const [inputLevel, setInputLevel] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isPlayingRecording, setIsPlayingRecording] = useState(false);
  const [isInVBMode, setIsInVBMode] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const speakerTesterRef = useRef<TestSpeakerReturn>();
  const microphoneTesterRef = useRef<TestMicrophoneReturn>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [time, setTime] = useState<number>(5000);
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [isHostStarted, setIsHostStarted] = useState(false);
  const [formatedTimer, setFormatedTimer] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
      const init = async () => {
        onCameraClick();
        onMicrophoneClick();
      }
      init();
    },[]);

    function getTimeDifferenceInSeconds(currentDateTime: string, targetDateTime: string): number {
      const currentDateTimeObj: Date = new Date(currentDateTime);
      const targetDateTimeObj: Date = new Date(targetDateTime);
  
      // Calculate the difference in milliseconds
      const differenceMs: number = targetDateTimeObj.getTime() - currentDateTimeObj.getTime();
  
      // Convert milliseconds to seconds
      const differenceSeconds: number = Math.floor(differenceMs / 1000);
  
      return differenceSeconds;
  }
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if(time<1){
          return false;
        }
        const currentDate = new Date();
        const options = { timeZone: 'UTC' };
       const currentDateTimeString = currentDate.toLocaleString('en-US', options);
        
        const timeDifference = getTimeDifferenceInSeconds(currentDateTimeString, bookdatetime);
        setTime(timeDifference);
        const hours: number = Math.floor(timeDifference / 3600);
        const minutes: number = Math.floor((timeDifference % 3600) / 60);
        const seconds: number = timeDifference % 60;
        
        if(timeDifference>0){
          setFormatedTimer(`${hours}h:${minutes}m:${seconds}s`);
        }else{
          setFormatedTimer(null);
        }
        if(minutes>0 && minutes<2 && !isMessageSend){
          const queryParams2 = new URLSearchParams(window.location.search);
          const mbid = queryParams2.get('mbid');
          const storedValue = localStorage.getItem(`ismsg_${mbid}`);
          if(storedValue){
            return false;
          }
         
          const apiHeaders = new Headers({
            "Content-Type": "application/json",
            // Add any additional headers if needed
          });
          const requestBody = {
            bId: mbid
            // Add other properties if needed
          };
        
        const requestOptions = {
          method: 'POST',
          headers: apiHeaders,
          body: JSON.stringify(requestBody),
        };
      
        fetch(`${apiUrl}/wp-json/video-call/v1/sendNotificationlast/`, requestOptions)
              .then(response => {
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
              }).then(result => {
                localStorage.setItem(`ismsg_${mbid}`,'yes');
                setIsMessageSend(true);
                console.log("done");
              });
        }
        
      }, 1000);

      

    }, [time]);

  const onCameraClick = useCallback(async () => {

    try{
        if (isStartedVideo) {
          await localVideo?.stop();
          setIsStartedVideo(false);
          setIsInVBMode(false);
          setIsBlur(false);
          updateVideoState(false);
        } else {
          if (videoRef.current) {
            await localVideo?.start(videoRef.current);
            setIsStartedVideo(true);
            updateVideoState(true);
          }
        }
    }catch(e){
        message.warning('Permission not granted. Kindly review and adjust your camera settings.');
    }
    
  }, [isStartedVideo]);
  const onMicrophoneClick = useCallback(async () => {
  try {
    if (isStartedAudio) {
      if (isMuted) {
        await localAudio?.unmute();
        micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
        setIsMuted(false);
        localStorage.setItem('isAudioOn','true');
      } else {
        await localAudio?.mute();
        if (micFeedBackInteval) {
          clearInterval(micFeedBackInteval);
        }
        setIsMuted(true);
        localStorage.setItem('isAudioOn','false');
      }
    } else {
      await localAudio?.start();
      localStorage.setItem('isAudioOn','true');
      setIsStartedAudio(true);
    }
  } catch (error) {
      message.warning('Permission not granted. Kindly review and adjust your microphone settings.');
    }
  }, [isStartedAudio, isMuted]);
  const onMicrophoneMenuClick = async (key: string) => {
    const [type, deviceId] = key.split('|');
    if (type === 'microphone') {
      if (deviceId !== activeMicrophone) {
        await localAudio.stop();
        setIsMuted(true);
        localAudio = ZoomVideo.createLocalAudioTrack(deviceId);
        await localAudio.start();
        setActiveMicrophone(deviceId);
      }
    } else if (type === 'leave audio') {
      await localAudio.stop();
      setIsStartedAudio(false);
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (localVideo) {
      if (activeCamera !== key) {
        await localVideo.switchCamera(key);
      }
    }
  };
  const onBlurBackground = useCallback(async () => {
    if (isInVBMode) {
      if (isBlur) {
        await localVideo.updateVirtualBackground(undefined);
      } else {
        await localVideo.updateVirtualBackground('blur');
      }
      setIsBlur(!isBlur);
    } else {
      if (!isBlur) {
        localVideo.stop();
        if (canvasRef.current) {
          localVideo.start(canvasRef.current, { imageUrl: 'blur' });
        }
        setIsInVBMode(true);
        setIsBlur(!isBlur);
      }
    }
  }, [isInVBMode, isBlur]);
  useMount(() => {
    mountDevices().then((devices) => {
      setMicList(devices.mics);
      setCameraList(devices.cameras);
      setSpeakerList(devices.speakers);
      if (devices.speakers.length > 0) {
        setActiveSpeaker(devices.speakers[0].deviceId);
      }
      if (devices.mics.length > 0) {
        setActiveMicrophone(devices.mics[0].deviceId);
      }
    });
  });
  const onTestSpeakerClick = () => {
    if (microphoneTesterRef.current) {
      microphoneTesterRef.current.destroy();
      microphoneTesterRef.current = undefined;
    }
    if (isPlayingAudio) {
      speakerTesterRef.current?.stop();
      setIsPlayingAudio(false);
      setOutputLevel(0);
    } else {
      speakerTesterRef.current = localAudio.testSpeaker({
        speakerId: activeSpeaker,
        onAnalyseFrequency: (value) => {
          setOutputLevel(Math.min(100, value));
        }
      });
      setIsPlayingAudio(true);
    }
  };
  const onTestMicrophoneClick = () => {
    if (speakerTesterRef.current) {
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = undefined;
    }
    if (!isPlayingRecording && !isRecordingVoice) {
      microphoneTesterRef.current = localAudio.testMicrophone({
        microphoneId: activeMicrophone,
        speakerId: activeSpeaker,
        recordAndPlay: true,
        onAnalyseFrequency: (value) => {
          setInputLevel(Math.min(100, value));
        },
        onStartRecording: () => {
          setIsRecordingVoice(true);
        },
        onStartPlayRecording: () => {
          setIsRecordingVoice(false);
          setIsPlayingRecording(true);
        },
        onStopPlayRecording: () => {
          setIsPlayingRecording(false);
        }
      });
    } else if (isRecordingVoice) {
      microphoneTesterRef.current?.stopRecording();
      setIsRecordingVoice(false);
    } else if (isPlayingRecording) {
      microphoneTesterRef.current?.stop();
      setIsPlayingRecording(false);
    }
  };
  let microphoneBtn = 'Test Microphone';
  if (isRecordingVoice) {
    microphoneBtn = 'Recording';
  } else if (isPlayingRecording) {
    microphoneBtn = 'Playing';
  }
  useUnmount(() => {
    if (isStartedAudio) {
      localAudio.stop();
    }
    if (isStartedVideo) {
      localVideo.stop();
    }
  });





  return (<>
    <div className="top-bar flex justify-between">
        <div className="top-bar-left span6">
          <div className="logo">
            <IconFont type="Logo"/>
            
          </div>
        </div>
        <div className="top-bar-right span6">
          <h2>{name}</h2>
        </div>
      </div>

       <div className="vedio-block">
        <div className="container">
          <div className="vedio-block-inner flex">
            <div className="vedio-block-inner-left span8">
              <div className="vedio-block-inner-left_inner">
                <div className="preview-video">
                  <video className={classNames({ 'preview-video-show': !isInVBMode })} muted={true} playsInline ref={videoRef} />
                  <canvas
                    className={classNames({ 'preview-video-show': isInVBMode })}
                    width="1280"
                    height="720"
                    ref={canvasRef}
                  />
                </div>

                <div className="video-footer video-operations video-operations-preview">
                   <div>
                      <MicrophoneButton
                        isStartedAudio={isStartedAudio}
                        isMuted={isMuted}
                        onMicrophoneClick={onMicrophoneClick}
                        onMicrophoneMenuClick={onMicrophoneMenuClick}
                        microphoneList={micList}
                        speakerList={speakerList}
                        activeMicrophone={activeMicrophone}
                        activeSpeaker={activeSpeaker}
                      />
                      <CameraButton
                        isStartedVideo={isStartedVideo}
                        onCameraClick={onCameraClick}
                        onSwitchCamera={onSwitchCamera}
                        onBlurBackground={onBlurBackground}
                        cameraList={cameraList}
                        activeCamera={activeCamera}
                        isBlur={isBlur}
                        isPreview={true}
                      />
                    </div>
                </div>
              </div>
            </div>

               <div className="vedio-block-inner-right span4">
                <h3>Ready to join?</h3>
                <div className="btn-block flex justify-center">
                  {time>1 && userRole==1 ?<Button  type="primary" disabled>
                    Start Now
                  </Button>:<Button  type="primary" onClick={showModal}>
                    Start Now
                  </Button>}
                </div>
                  {formatedTimer && <p className="timeFormater">{formatedTimer}</p>}
              </div>
          </div>
        </div>
      </div>
      <Modal
        title="Terms and Conditions"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => onLeaveOrJoinSession(history)} disabled={!isChecked}>
            Accept
          </Button>,
        ]}
      >
        <p><input
            type="checkbox"
            name="agree"
            required
            checked={isChecked}
            onChange={handleCheckboxChange}
          />  Reminder! Please make sure to abide by our <a target="_blank" href={`${process.env.REACT_APP_SERVER_URI}/terms`}>Terms of Service</a>.</p>
      </Modal>
  </>);
}
export default Home;