import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
const { Button: DropdownButton } = Dropdown;
const { Item: MenuItem } = Menu;
interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isHost } = props;

  return (
      <Button
      className={classNames('vc-button red-button')}
      icon={<IconFont type="icon-call" />}
      // eslint-disable-next-line react/jsx-boolean-value
      ghost={true}
      shape="circle"
      size="large"
      onClick={onLeaveClick}
      title="Leave session"
    />
    );
  // return isHost ? (
  //   <DropdownButton
  //     className="vc-dropdown-button red-button"
  //     size="large"
  //     menu={getAntdDropdownMenu([getAntdItem('End session', 'end')], onEndClick)}
  //     trigger={['click']}
  //     type="ghost"
  //     onClick={onLeaveClick}
  //     icon={<IconFont type="icon-call" />}
  //     placement="topRight"
  //   >
  //     <IconFont type="icon-leave" />
  //   </DropdownButton>
  // ) : (
  //   <Button
  //     className={classNames('vc-button red-button')}
  //     icon={<IconFont type="icon-call" />}
  //     // eslint-disable-next-line react/jsx-boolean-value
  //     ghost={true}
  //     shape="circle"
  //     size="large"
  //     onClick={onLeaveClick}
  //     title="Leave session"
  //   />
  // );
};

export { LeaveButton };
