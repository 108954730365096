import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Input,Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import ChatMessageItem from './component/chat-message-item';
import ChatReceiverContainer from './component/chat-receiver';
import { useChat } from './hooks/useChat';
import './chat.scss';

const { TextArea } = Input;

interface ChatContainerProps {
  onCloseChat: () => void;
}
const ChatContainer = (props: ChatContainerProps) => {
   const { onCloseChat } = props;
  const zmClient = useContext(ZoomContext);
  const chatClient = zmClient.getChatClient();
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  const {
    records,
    receivers,
    privilege,
    chatUser,
    isHostOrManager,
    setChatUserId,
    sendMessage,
    sendFile,
    resendFile,
    downloadFile
  } = useChat(zmClient, chatClient);

  const onChatInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatDraft(event.target.value);
  }, []);
  const onChatPressEnter = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      if (chatUser && chatDraft) {
        sendMessage(chatDraft);
        setChatDraft('');
      }
    },
    [sendMessage, chatUser, chatDraft]
  );

  const sendOnclick=()=>{
    if (chatUser && chatDraft) {
        sendMessage(chatDraft);
        setChatDraft('');
      }
  }
  useLayoutEffect(() => {
    if (chatWrapRef.current) {
      chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
    }
  }, [records]);
  return (
    <div className="chat-container">
      <div className="chat-wrap">
        <h2>Chat</h2>
        <div className="close-btn-chat">
            <Button onClick={onCloseChat}>X</Button>
        </div>
        
        <div className="chat-message-wrap" ref={chatWrapRef}>
          {records.map((record) => (
            <ChatMessageItem
              record={record}
              currentUserId={zmClient.getSessionInfo().userId}
              setChatUser={setChatUserId}
              key={record.timestamp}
              resendFile={resendFile}
              downloadFile={downloadFile}
            />
          ))}
        </div>
        {ChatPrivilege.NoOne !== privilege || isHostOrManager ? (
          <>
            {/* <ChatReceiverContainer
              chatUsers={chatReceivers}
              selectedChatUser={chatUser}
              isHostOrManager={isHost || isManager}
              chatPrivilege={chatPrivilege}
              setChatUser={setChatUserId}
            /> */}
            <div className="chat-message-box">
              <TextArea
                onPressEnter={onChatPressEnter}
                onChange={onChatInput}
                value={chatDraft}
                placeholder="Type message here ..."
              />
              <SendOutlined onClick={sendOnclick}/>
            </div>
          </>
        ) : (
          <div className="chat-disabled">Chat disabled</div>
        )}
      </div>
    </div>
  );
};

export default ChatContainer;
