import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import {useEffect, useState, useContext } from 'react';
import './report-btn.scss';
import CountdownTimer from './CountdownTimer';


const ReportBtn = () => {
    return (<CountdownTimer/>);
  };

export default ReportBtn;
